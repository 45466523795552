import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import {DialogTitle} from "@material-ui/core";

export default props => {
  
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <button className="btn btn-primary" onClick={handleClickOpen}>
        {props.buttonTitle}
      </button>      
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth="sm"
      >
        <div className="button-downloads">  
          <DialogTitle id="responsive-dialog-title">{props.explainText}</DialogTitle>
          <DialogContent>
            <DialogContentText>                
                {props.documents.map(document => (
                  <a target="_blank" href={document.document.url} className="btn btn-primary" rel="noopener noreferrer">
                    {document.title}
                  </a>
                ))}               
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Fechar</Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}