import React from 'react'

export default function Prices() {
  return (
    <div id="prices" className="prices-area">
      <div className="container">
          <div className="section-title">
            <h2>Faça sua inscrição</h2>
          </div>
          <div className="row h-100 align-items-center">
            <table className="promocao">
              <thead>
                <tr>
                  <th>Categoria</th>
                  <th>Valor</th>
                  <th>Lote</th>
                  <th>Inscrições</th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg1">
                  <td className="line-bottom">Profissional da contabilidade - Registro ativo<hr /></td>
                  <td className="line-bottom">Esgotado <hr /></td>
                  <td rowSpan="3">1º lote</td>
                  <td rowSpan="3">01 à 1.000</td>
                </tr>
                <tr className="bg1">
                  <td className="line-bottom">Estudante de ciências contábeis*<hr /></td>
                  <td className="line-bottom">Esgotado <hr /></td>
                </tr>
                <tr className="bg1">
                  <td>Outras profissões</td>
                  <td>Esgotado</td>
                </tr>
                <tr className="bg2">
                  <td className="line-bottom">Profissional da contabilidade - Registro ativo <hr /></td>
                  <td className="line-bottom">R$220<hr /></td>
                  <td rowSpan="3">2º lote</td>
                  <td rowSpan="3">1.001 à 2.000 </td>
                </tr>
                <tr className="bg2">
                  <td className="line-bottom">Estudante de ciências contábeis*<hr /></td>
                  <td className="line-bottom">R$110<hr /></td>
                </tr>
                <tr className="bg2">
                  <td>Outras profissões</td>
                  <td>R$300</td>
                </tr>
                <tr className="bg1">
                  <td className="line-bottom">Profissional da contabilidade - Registro ativo<hr /></td>
                  <td className="line-bottom">R$270<hr /></td>
                  <td rowSpan="3">3º lote</td>
                  <td rowSpan="3">Acima de 2.000 </td>
                </tr>
                <tr className="bg1">
                  <td className="line-bottom">Estudante de ciências contábeis*<hr /></td>
                  <td className="line-bottom">R$135 <hr /></td>
                </tr>
                <tr className="bg1">
                  <td>Outras profissões</td>
                  <td>R$350</td>
                </tr>
              </tbody>
              
            </table>
          </div>
          <div className="h-100 align-items-center ">
            <span className="notes" >
              * Estudante – Poderá ser exigido, após a realização de todos os procedimentos para inscrição no site, o comprovante do vínculo estudantil no curso de ciência contábil, não sendo permitida a inscrição de estudantes de outros cursos ou de estudantes com registro profissional ativo. A não comprovação do vínculo estudantil atual ensejará o cancelamento da inscrição.  
            </span>
          </div>
        </div>
    </div>
  )
}
