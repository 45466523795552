import React from 'react';
import ModalVideo from 'react-modal-video';
import '../../../node_modules/react-modal-video/scss/modal-video.scss';
import background from '../../assets/images/convention_bg.jpg';
import logo from '../../assets/images/logo_convention.svg';

class MainBanner extends React.Component {
  state = {
    days: '',
    hours: '',
    minutes: '',
    seconds: '',
    isOpen: false,
  };

  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  openModal = () => {
    this.setState({ isOpen: true });
  };

  componentWillUnmount() { }

  render() {
    return (
      <React.Fragment>
        <ModalVideo
          channel='youtube'
          isOpen={this.state.isOpen}
          videoId='cRXm1p-CNyk'
          onClose={() => this.setState({ isOpen: false })}
        />

        {/* <div className='main-banner item-bg1' style={{ backgroundImage: "url(" + this.props.global.imageBanner + ")" }}> */}
        <div className='main-banner item-bg1' style={{ backgroundImage: `url(${background})` }}>
          <div className='d-table'>
            <div className='d-table-cell'>
              <div className='container'>
                <div className='main-banner-content'>
                  <div className="banner-logo">
                    {/* <img src={this.props.global.image} alt="logo" /> */}
                    <img src={logo} alt="logo" />
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="titlebanner" dangerouslySetInnerHTML={{ __html: this.props.global.titleBanner }}></div>
                      <div className="descriptionBanner" dangerouslySetInnerHTML={{ __html: this.props.global.descriptionBanner }}></div>
                      <div className="dateOfEventBanner" dangerouslySetInnerHTML={{ __html: this.props.global.dateOfEventBanner }}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='shape1'>
            <img
              src={require('../../assets/images/shapes/1.png')}
              alt='shape1'
            />
          </div>

          <div className='shape2 rotateme'>
            <img
              src={require('../../assets/images/shapes/2.png')}
              alt='shape2'
            />
          </div>

          <div className='shape3 rotateme'>
            <img
              src={require('../../assets/images/shapes/3.png')}
              alt='shape3'
            />
          </div>

          <div className='shape4'>
            <img
              src={require('../../assets/images/shapes/4.png')}
              alt='shape4'
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default MainBanner;
