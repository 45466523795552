import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    padding: 10,
    paddingTop: 80,
    paddingBottom: 90,
    backgroundColor: '#fbf9f9',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

  },
  tableHead: {
    padding: 10,
    fontWeight: 700,
  },
  table: {
    maxWidth: 750,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default function Points() {
  const classes = useStyles();

  return (
    <div style={{ paddingBottom: 60, paddingTop: 60, backgroundColor: '#fbf9f9' }}>
      <div className="container">
        <div className="section-title">
          <h2 >Pontuação</h2>
        </div>
        <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
          <TableContainer component={Paper} className={classes.table} >
            <div className={classes.tableHead}>PROGRAMA DE EDUCAÇÃO PROFISSIONAL CONTINUADA (PEPC)</div>
            <Table className="desktop" aria-label="Tabela Pontuação PEPC">
              <TableHead>
                <TableRow>
                  <TableCell align="center">AUD</TableCell>
                  <TableCell align="center">BCB</TableCell>
                  <TableCell align="center">SUSEP</TableCell>
                  <TableCell align="center">PREVIC</TableCell>
                  <TableCell align="center">PROGP-RT</TableCell>
                  <TableCell align="center">PERITOS</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow >
                  <TableCell align="center">18</TableCell>
                  <TableCell align="center">14,5</TableCell>
                  <TableCell align="center">14,5</TableCell>
                  <TableCell align="center">14,5</TableCell>
                  <TableCell align="center">18</TableCell>
                  <TableCell align="center">18</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Table className="mobile" aria-label="Tabela Pontuação PEPC">
              <TableBody>
                <TableRow>
                  <TableCell variant="head">AUD</TableCell>
                  <TableCell>18</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">BCB</TableCell>
                  <TableCell>14,5</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">SUSEP</TableCell>
                  <TableCell>14,5</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">PREVIC</TableCell>
                  <TableCell>14,5</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">PROGP-RT</TableCell>
                  <TableCell>18</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">PERITOS</TableCell>
                  <TableCell>18</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  )
}
