import React from "react";
import { Link } from "react-router-dom";
import lax from "lax.js";
import OwlCarousel from "react-owl-carousel3";
import api from "../../services/api";
import documentApi from "../../services/document.service";
import Download from "../Shared/Download";

const options = {
  nav: false,
  dots: false,
  autoplayHoverPause: true,
  autoplay: true,
  navText: [
    "<i class='icofont-rounded-left'></i>",
    "<i class='icofont-rounded-right'></i>",
  ],
  responsive: {
    0: {
      items: 2,
    },
    400: {
      items: 3,
    },
    576: {
      items: 3,
    },
    768: {
      items: 4,
    },
    1200: {
      items: 5,
    },
  },
};

class Partner extends React.Component {
  state = {
    sponsors: [],
    typeSponsor: [],
    documents: []
  };

  constructor(props) {
    super(props);
    lax.setup();

    document.addEventListener(
      "scroll",
      function (x) {
        lax.update(window.scrollY);
      },
      false
    );

    lax.update(window.scrollY);
  }

  componentDidMount() {
    const getSponsors = async () => {
      const response = await api.post(
        "/database/sponsors__c/aggregate",
        [
          { $group: { _id: "$typeSponsor", order: { $addToSet: "$order" } } },
          { $sort: { order: 1 } },
          {
            $lookup: {
              from: "sponsors__c",
              localField: "order",
              foreignField: "order",
              as: "sponsors",
            },
          },
          { $project: { _id: 1, sponsors: 1 } },
        ],
        {
          headers: {
            Authorization:
              "Basic " +
              window.btoa(
                process.env.REACT_APP_APIKEY +
                ":" +
                process.env.REACT_APP_PUBLIC_SECRETKEY
              ),
          },
        }
      );

      this.setState({ sponsors: response.data });
    };

    documentApi.getAll()
      .then(results => {
        this.setState({ documents: results.data });
      });

    getSponsors();
  }

  render() {

    const { sponsors } = this.state;

    const sponsorsList = sponsors && sponsors[0] ? sponsors.filter(s => s._id !== 'Apoiador Master') : [];
    const sponsorsListPremium = sponsors && sponsors[0] ? sponsors.filter(s => s._id === 'Apoiador Master') : [];
    const listPremium = [
      {
        title: 'Patrocínio Master',
        sponsors: sponsorsListPremium.length ? sponsorsListPremium[0].sponsors.filter(s => s.levelSponsor === 'MASTER') : []
      },
      {
        title: 'Patrocínio Diamante',
        sponsors: sponsorsListPremium.length ? sponsorsListPremium[0].sponsors.filter(s => s.levelSponsor === 'DIAMANTE') : []
      },
      {
        title: 'Patrocínio Ouro',
        sponsors: sponsorsListPremium.length ? sponsorsListPremium[0].sponsors.filter(s => s.levelSponsor === 'OURO') : []
      },
      {
        title: 'Patrocínio Prata',
        sponsors: sponsorsListPremium.length ? sponsorsListPremium[0].sponsors.filter(s => s.levelSponsor === 'PRATA') : []
      },
      {
        title: 'Patrocínio Virtual',
        sponsors: sponsorsListPremium.length ? sponsorsListPremium[0].sponsors.filter(s => s.levelSponsor === 'VIRTUAL') : []
      }
    ];
    
    return (
      <section
        id="sponsors"
        className="partner-area ptb-120"
        style={{ display: "block !important" }}
      >
        <div className="container">
          <div className="section-title">
            <h2>Patrocinadores</h2>
            <Download
              buttonTitle="Seja um patrocinador"
              documents={this.state.documents}
              explainText="Baixe aqui o edital de chamamento público e a proposta de patrocínio." />
          </div>
          {
            !!sponsorsList.length && (
              <div className="row">
                {sponsorsList.map((item, index) => (
                  <React.Fragment key={item._id}>
                    <div className="col-lg-12">
                      <div
                        className={`partner-title  ${index % 2 === 0 ? "platinum-sponsor" : "gold-sponsor"
                          }`}
                      >
                        <h3 className="lax" data-lax-preset="driftRight">
                          {item._id}
                        </h3>
                      </div>
                    </div>
                    <OwlCarousel
                      className={`platinum-partner-slides owl-carousel owl-theme ${item.sponsors.length >= 5 ? '' : 'center-carousel'}`}
                      {...options}
                      loop={item.sponsors.length >= 5}
                    >
                      {item.sponsors.map((sponsor) => (
                        <div key={sponsor._id} className="col-lg-12 col-md-12">
                          <div className="partner-item">
                            <Link to="#">
                              <img src={sponsor.image} alt="Partner Logo" />
                              <img src={sponsor.image} alt="Partner Logo" />
                            </Link>
                          </div>
                        </div>
                      ))}
                    </OwlCarousel>
                    <div className="col-lg-12">
                      <div className="border"></div>
                    </div>
                  </React.Fragment>
                ))}
              </div>
            )
          }
          <div className="partner-master">
            {
              listPremium.map((s, index) => {
                if (!!s.sponsors.length) {
                  return (
                    <div className="partner-master-item" key={index}>
                      <h3 className="lax">{s.title}</h3>
                      <div>
                        {
                          s.sponsors.map((_s, index) => (
                            <Link key={index} to="#">
                              <img src={_s.image} alt="Partner Logo" />
                            </Link>
                          ))
                        }
                      </div>
                    </div>
                  );
                }
                return null;
              })
            }
          </div>
        </div>
      </section>
    );
  }
}

export default Partner;
