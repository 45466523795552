import React from "react";

class Footer extends React.Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <footer className="footer-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="copyright-area">
                <p>
                  <i className="icofont-copyright"></i> Copyright 2021 INOVA3D - All rights reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
