import React from 'react'
import Principal from '../../assets/images/principal-pic-1.jpg'

export default function FeaturedPerson() {
  return (
    <div className="feature-area">
      <div className="photo-area">
        <img src={Principal} alt="Rosa Maria Abreu Barros" width="240px" height="240px" />
      </div>
      <div className="description-area">
        <p className="quote">"</p>
        <div className="description-content">
          <p className="quote-text">Palavra da Presidente</p>
          <p><i>É com grande alegria que convido os profissionais da contabilidade de Minas Gerais e também os colegas de todo o Brasil para participar de nossa XIII Convenção de Contabilidade 
            de Minas gerais. Em uma edição virtual, totalmente interativa, onde foram aplicadas as melhores e mais modernas ferramentas tecnológicas do mercado, 
            teremos uma experiência ainda mais extraordinária do que em um evento presencial. Nossa Convenção será lembrada por seu ineditismo, pela qualidade de seus palestrantes e a versatilidade de seu conteúdo.
            Focamos em um único objetivo: trazer para os profissionais da contabilidade uma experiência única, contemporânea, inovadora, e acima de tudo, enriquecedora.
            <br />Não percam!</i>
          
          </p>
          <div className="assign">
            <p className="title">Rosa Maria Abreu Barros </p>
            <span className="subtitle">Presidente do Conselho Regional de Contabilidade de Minas Gerais</span>
          </div>          
        </div>
      </div>
    </div>
  )
}
