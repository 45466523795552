import api from "./api";

class DocumentService {
  static getAll = () => {    
    return api.get("/database/documents__c", {headers:   {
      Authorization: "Basic " + window.btoa(process.env.REACT_APP_APIKEY + ":" + process.env.REACT_APP_INTEGRATION_SECRETKEY)
    }});
  }
}

export default DocumentService;
