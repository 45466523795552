import React from "react";
import { Link } from "react-router-dom";
import lax from "lax.js";
import { DateTime } from "luxon";
import api from "../../services/api";

class EventSchedules extends React.Component {
  state = {
    schedule: [[]],
    date: [],
    day: [
      "Primeiro dia",
      "Segundo dia",
      "Terceiro dia",
      "Quarto dia",
      "Quinto dia",
      "Sexto dia",
      "Sétimo dia",
      "Oitavo dia",
      "Nono dia",
      "Decimo dia",
    ],
  };

  constructor(props) {
    super(props);
    lax.setup();

    document.addEventListener(
      "scroll",
      function (x) {
        lax.update(window.scrollY);
      },
      false
    );

    lax.update(window.scrollY);
  }

  openTabSection = (evt, tabNmae) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_item");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabNmae).style.display = "block";
    evt.currentTarget.className += "current";
  };

  componentDidMount() {
    const getSchedule = async () => {
      const res = await api.post(
        "/database/auditorio_master_feira__c/aggregate",
        [
          {
            $sort: {
              startTime: 1,
            },
          },
        ],
        {
          headers: {
            Authorization:
              "Basic " +
              window.btoa(
                process.env.REACT_APP_APIKEY +
                  ":" +
                  process.env.REACT_APP_PUBLIC_SECRETKEY
              ),
          },
        }
      );

      let data = [];
      const temp = [];
      const date = [];

      res.data.map((item) => {
        let day = null;
        const dateParser = DateTime.fromISO(
          new Date(item.startTime).toISOString(),
          { zone: "America/Sao_Paulo" }
        );
        temp.forEach((i) => {
          if (i === dateParser.toFormat("d")) {
            day = dateParser.toFormat("d");
          }
        });

        if (!day) {
          temp.push(dateParser.toFormat("d"));
          date.push(dateParser);
        }

        if (!data[temp.indexOf(dateParser.toFormat("d"))]) {
          data[temp.indexOf(dateParser.toFormat("d"))] = [];
        }

        data[temp.indexOf(dateParser.toFormat("d"))].push(item);

        return (item.startTime = dateParser);
      });
      
      //console.log(data);
      
      this.setState({ schedule: data });
      this.setState({ date: date });
    };

    getSchedule();
  }
  
  getTimeSchedule(startTime){
    const min = startTime.setLocale("pt-br").toFormat("mm");
    if(min !== '00')
      return `${startTime.setLocale("pt-br").toFormat("H")}h${min}`;
    else{
      return `${startTime.setLocale("pt-br").toFormat("H")}h`;
    }
  }

  render() {
    return (
      <section id="schedule" className="schedule-area bg-image ptb-120">
        <div className="container">
          <div className="section-title">
            <h2>Programação</h2>
            <span>Reserve na sua agenda</span>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="tab">
                <ul className="tabs active">
                  {this.state.date.map((item, index) => (
                    <li
                      key={index}
                      onClick={(e) => this.openTabSection(e, "tab" + index + 1)}
                      className={index === 0 ? "current" : ""}
                    >
                      <div>
                        <span className="dateSchedule">
                          {item.setLocale("pt-br").toFormat("dd/MMM").replace('.','')}
                        </span>
                        <span className="sequenceDay">{this.state.day[index]}</span>
                      </div>
                    </li>
                  ))}
                </ul>

                <div className="tab_content">
                  {this.state.schedule.map((i, index) => (
                    <div
                      key={index}
                      id={"tab" + index + 1}
                      className="tabs_item"
                    >
                      <ul className="accordion">
                        {i.map((item) => (
                          <li key={item._id} className="accordion-item">
                            <Link className="accordion-title" to="#">
                              <div className="row schedule-info">
                                <div className="col-md-1 col-sm-2 time">
                                  <span>{this.getTimeSchedule(item.startTime)}</span>
                                </div>
                                <div className="col-md-11 col-sm-10">
                                  <div className="row">
                                    <h3>{item.title}</h3>
                                  </div>
                                  {item.description && (
                                    <div className="row description">
                                      <span>{item.description}</span>
                                    </div>
                                  )}

                                  
                                  
                                  {item.presenters?.map((presenter, index) => (
                                    <div className="row presenter" key={index}>
                                      {presenter.image && (
                                        <div className="col-md-2">
                                          <img src={presenter.image} alt="Palestrante"/>
                                        </div>
                                      )}
                                      <div className="col-md-10 name">
                                        <h4>{presenter.name}</h4>
                                        {presenter.presentation && (
                                          <h5>{presenter.presentation}</h5>
                                        )}
                                        {presenter.resume && (
                                          <p>{presenter.resume}</p>
                                        )}
                                      </div>
                                    </div>
                                  ))}

                                  {item.mediators?.length > 0 && (
                                    <div className="row">
                                      <div className="col-md-2">
                                      </div>
                                      <div className="col-md-10">
                                        {item.mediators?.length > 1 && (
                                          <h6>Mediadores:</h6>
                                        )}
                                        {item.mediators?.length === 1 && item.mediators[0].gender === 'male' && (
                                            <h6>Mediador:</h6>  
                                        )}
                                        {item.mediators?.length === 1 && item.mediators[0].gender === 'female' && (
                                          <h6>Mediadora:</h6>
                                        )}
                                      </div>
                                    </div>
                                    
                                  )}

                                  {item.mediators?.map((mediator, index) => (
                                    <div className="row mediator" key={index}>
                                      <div className="col-md-2">
                                      </div>
                                      <div className="col-md-10">
                                        <span>{mediator.name}</span>
                                      </div>
                                    </div>
                                  ))}
                                </div>        
                              </div>
                            </Link>
                            <hr />
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="shape1">
          <img src={require("../../assets/images/shapes/1.png")} alt="shape1" />
        </div>
        <div className="shape2 rotateme">
          <img src={require("../../assets/images/shapes/2.png")} alt="shape2" />
        </div>
        <div className="shape3 rotateme">
          <img src={require("../../assets/images/shapes/3.png")} alt="shape3" />
        </div>
        <div className="shape4">
          <img src={require("../../assets/images/shapes/4.png")} alt="shape4" />
        </div>
      </section>
    );
  }
}

export default EventSchedules;
